<template>
	<div class="page">
		<el-row class="tool-bar">
			<el-col :span="12">
				<search-tool v-model="search" @input="$utils('search', getKursnaLista)"/>
			</el-col>
		</el-row>
		<el-row>
			<el-col>
				<ve-line :data="kursChartData" :settings="kursChartSettings"></ve-line>
			</el-col>
		</el-row>
		<el-row>
			<!--Table-->
			<el-table
					v-if="kursnaLista.length"
					:data="kursnaLista"
					ref="table"
					:default-sort="sortBy"
					@sort-change="sort => $utils('changeSort', sort, getKursnaLista)"
					v-loading="gridLoad"
					border
					stripe>
				<el-table-column
						align="center"
						prop="datum"
						:label="$t('kursnaLista.datum')"
						sortable="custom">
					<template slot-scope="scope">
						{{$utils('formatDate', scope.row.datum)}}
					</template>
				</el-table-column>
				<el-table-column
						align="center"
						prop="eur"
						:label="$t('kursnaLista.eur')"
						sortable="custom">
					<template slot-scope="scope">
						{{$utils('formatBroja', scope.row.eur, 4)}}
					</template>
				</el-table-column>
				<el-table-column
						align="center"
						prop="chf"
						:label="$t('kursnaLista.chf')"
						sortable="custom">
					<template slot-scope="scope">
						{{$utils('formatBroja', scope.row.chf, 4)}}
					</template>
				</el-table-column>
				<el-table-column
						align="center"
						prop="eur-chf"
						:label="$t('kursnaLista.eurChf')"
						sortable="custom">
					<template slot-scope="scope">
						{{$utils('formatBroja', scope.row['eur-chf'], 4)}}
					</template>
				</el-table-column>
				<el-table-column
						align="center"
						prop="chf-eur"
						:label="$t('kursnaLista.chfEur')"
						sortable="custom">
					<template slot-scope="scope">
						{{$utils('formatBroja', scope.row['chf-eur'], 4)}}
					</template>
				</el-table-column>
			</el-table>
			<el-alert v-else :title="$t('global.nemaPodataka')" type="warning" :closable="false"/>
		</el-row>
		<br>
		<el-row>
			<pagination v-model="pagination" @input="getKursnaLista" ref="pagination"/>
		</el-row>
	</div>
</template>

<script>
//
export default {
	name: 'kursna-lista-list',
	data() {
		return {
			kursChartData: {
				columns: ['datum', 'eur', 'chf'],
				rows: []
			},
			kursChartSettings: {
				metrics: ['eur', 'chf'],
				dimension: ['datum'],
				labelMap: {
					eur: 'EUR',
					chf: 'CHF'
				}
			},
			kursnaLista: [],
			gridLoad: false,
			pagination: {},
			search: '',
			sortBy: {
				prop: 'id',
				order: 'descending'
			}
		};
	},
	mounted() {
		this.$store.dispatch('setHeader', {
			title: this.$t('kursnaLista.kursnaLista')
		});

		this.$utils('autoLoad');
		this.$utils('stopLoadingAfter', [this.getKursnaLista()]);
	},
	methods: {
		getKursnaLista() {
			this.gridLoad = true;
			return this.$get('kursnaLista', this.$utils('getParams')).then(data => {
				this.kursnaLista = data.content;
				this.kursChartData.rows = data.content;
				this.$utils('setPag', data);
				this.$utils('autoSave');
				this.gridLoad = false;
			});
		}
	}
};
</script>
